import React from "react";


export default function Footer() {

    return(
        <>
            <footer>
                <p>&copy; Copyright codelite.co.in | All rights reserved.</p>
            </footer>
        </>
    );
}
